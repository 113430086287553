import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import emailReducer from "../features/emails/emailSlice";
import groupReducer from "../features/groups/groupSlice";
import memberReducer from "../features/members/memberSlice";
import menuReducer from "../features/menu/menuSlice";
import uploadReducer from "../features/upload/uploadSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    menu: menuReducer,
    members: memberReducer,
    groups: groupReducer,
    emails: emailReducer,
    upload: uploadReducer,
  },
});
