import axios from "axios";

let API_URL = "";
if (process.env.NODE_ENV !== "production") {
  API_URL = "http://localhost:5000/api/groups/"; // lets us use the backend server in local development
}
else {
  API_URL = "/api/groups/";
}

// GET all members
const getAllGroups = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data } = await axios.get(API_URL, config);
  return data;
};

// POST generate photo
// Last Edited May 16th 2023 by Devin Atkin
// Added Title Text and Bottom Text to the selection. 
const genGroupPhoto = async (groupData, token) => {
  console.log("groupData", groupData);
  const { data } = await axios.post(
    "https://us-central1-fl2f-1.cloudfunctions.net/generate_group_photograph",
    JSON.stringify({
      groupID: `${groupData.groupID}`,
      today: `${groupData.today}`,
      extraPeople: `${groupData.extraPeople}`,
      bottomText: `${groupData.bottomText}`,
      titleText: `${groupData.titleText}`
    })
  );
  return data.URL;
};

// PUT update the bottomText for a group for groupPhoto
const updateBottomText = async (groupData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { groupID } = groupData;
  const { data } = await axios.put(API_URL + groupID, groupData, config);
  return data;
};

const memberService = { getAllGroups, genGroupPhoto, updateBottomText };

export default memberService;
