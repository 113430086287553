import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const PrivateRoutes = () => {
    const { user } = useSelector((state) => state.auth);
    const location = useLocation();

    if (!user) {
        // Redirect to login and remember the current location
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    if (user.role === "admin" || user.role === "facilitator") {
        return <Outlet />;
    } else if (user.role === "participant") {
        return <Navigate to="/uploadPhoto" replace />;
    } else {
        // Redirect to a default page if the user's role is not recognized
        return <Navigate to="/login" replace />;
    }
};

export default PrivateRoutes;
